.header{
    display: flex;
    justify-content: space-between;
}
.logo{
 width: 10rem;
 height: 3rem;
}
.header-menu{
    list-style: none;
    display: flex ;
    gap: 2rem;
    color: white;
}

.header-menu>li:hover{
    
    color: var(--orange);
    cursor: pointer;
}

@media screen
and (max-width: 768px)
{
    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-menu{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
    }
}